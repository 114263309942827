import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
import productImage from "../../../assets/images/auger.jpg";
import productImage1 from "../../../assets/images/auger1.jpg";
import "../products.css";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";


function auger() { 

  return (
    <div>
      <Topbanner title="Earth Auger" Bg={paroBg} />

      <div className=" lb_product_wrap">
      <div className="row">
          <div className="col l4">
            <Heading styles="center" title="Earth Auger" />
        </div>
        <div className="col l8">
            <img width={340} src={productImage} alt="" />
            <img width={340} src={productImage1} alt="" />
        </div>
        
        </div>
        <div className="row">
          <div className="col l12">

            <AungerTableOne/>

            <AungerTableTwo/>

          </div>
        </div>
      </div>
    </div>
  );
}

export default auger;




// { <ProductDetail 

// TdTitle={''}
// TdOne={''} TdTwo={''}  

// TdThree={TdThree}
// TdFour={TdFour}
// TdFive={TdFive}

// TdSix={TdSix}
// TdSeven={TdSeven}
// TdEight={TdEight}

// /> }


const  AungerTableOne = ()=>{


const TdTitle = ['Models -->' ,'Unit ' ,'BMA20 ' ,'BMA25' ,'BMA30' ,'BMA35' ,'BMA45' ,'BMA50' ,'BMA55' ,'BMA60']
const TdOne = ['Carrier','T','1~2.5' ,'1.5~3',' 2~3.5','2.5~4.5',' 3~5',' 4.5~7',' 5~7', '6~8']
const TdTwo = ['Torque Maximum'	,'N.m',	'1871',	'2432',	'2877',	'3614',	'4218',	'5056'	,'5910',	'6150']
const TdThree = ['Pressure','	Bar',	'205',	'205','	240',	'240',	'240',	'240',	'240',	'240']
const TdFour  =['oil Flow Range',	'L/min'	,'23~53',	'30~61',	'30~61'	,'30~68',	'38~76',	'38~76',	'45~83',	'45~106']
const TdFive  = ['Rotary Speed'	,'rpm',	'40~92',	'40~82',	'40~81',	'32~72',	'32~64'	,'29~58',	'28~50','34~80' ]
const TdSix  = ['Hydraulic Hose',	'Inch',	'1/2'	,'1/2',	'1/2',	'1/2',	'1/2',	'1/2'	,'1/2',	'3/4']
const TdSeven = ['Output Shaft'	,'mm',	'Ф65',	'Ф65',	'Ф65'	,'Ф65',	'Ф65'	,'Ф75',	'Ф75',	'Ф75']
const TdEight  = ['Unit Weight'	,'Kg',	'54'	,'54'	,'71',	'71'	,'71'	,'108','108','110'	]
const TdNine = ['Unit Height','mm', '595', '595', '700', '700', '700', '780', '780','850']
const TdTen = ['Unit Max.Dia.',	'mm',	'200',	'200',	'244',	'244',	'244',	'269',	'269',	'269']
const TdEleven = ['Auger Length',	'mm','1200',	'1200',	'1200',	'1200',	'1200',	'1500',	'1500',	'1500']
const TdTwelve = ['Auger dia. Range',	'mm',	'100~400', '100~500',	'100~600',	'100~750',	'100~900',	'150~600',	'150~750',	'150~900']
  return(
 <>
 <ProductDetail 
TdTitle={TdTitle}

TdImg= {''}

TdOne={TdOne} 
TdTwo ={TdTwo }
TdThree={TdThree}
TdFour={TdFour}
TdFive={TdFive}
TdSix={TdSix }
TdSeven={TdSeven}
TdEight={TdEight}
TdNine={TdNine}
TdTen={TdTen}
TdEleven={TdEleven}
TdTwelve={TdTwelve}
/>
 
 </>
  )
}


const  AungerTableTwo = ()=>{

  const TdTitle = ['Models -->' ,'Unit ',	'BMA70',	'BMA80',	'BMA100',	'BMA120',	'BMA150',	'BMA200',	'BMA250',	'BMA500']
const TdOne = ['Carrier',	'T',	'7~10',	'8~12',	'10~13',	'13~17',	'13~17',	'13~20',	'15~23',	'21~50']
const TdTwo = ['Torque Maximum',	'N.m',	'6931',	'8048',	'10778',	'11976',	'15046',	'19039',	'24949',	'50195']
const TdThree = ['Pressure',	'Bar',	'240',	'240',	'240',	'240',	'240',	'240',	'250',	'280']
const TdFour  =['Oil Flow Range',	'l/min',	'61~121',	'61~136',	'70~136',	'80~140',	'80~151',	'80~170',	'90~180',	'100~280']
const TdFive  = ['Rotary Speed',	'rpm',	'37~72',	'29~64',	'26~45',	'22~43',	'20~39',	'17~34',	'16~30',	'11~30' ]
const TdSix  = ['Hydraulic Hose',	'Inch',	'3/4',	'3/4',	'3/4',	'1',	'1',	'1',	'1',	'1 1/2']
const TdSeven = ['Output Shaft',	'mm',	'Ф75',	'Ф75',	'Ф75',	'Ф75',	'Ф75',	'Ф75',	'Ф75',	'Ф110']
const TdEight  = ['Unit Weight',	'Kg',	'112',	'115',	'167',	'167',	'167',	'185',	'185',	'480']
const TdNine = ['Unit Height',	'mm',	'850',	'850',	'930',	'930',	'930',	'930',	'930',	'14320']
const TdTen = ['Unit Max.Dia.',	'mm',	'269',	'269',	'290',	'290',	'290',	'290',	'290',	'450']
const TdEleven = ['Auger Length',	'mm',	'1500',	'1500',	'1750',	'1750',	'1750',	'1750',	'1750',	'2300']
const TdTwelve = ['Auger dia. Range',	'mm',	'150~900',	'150~00',	'150~900',	'150~900',	'150~1200',	'150~1200',	'150~1200',	'300~2400']
  return(
    <>
    <ProductDetail 
TdTitle={TdTitle}
TdImg= {''}

TdOne={TdOne} 
TdTwo ={TdTwo }
TdThree={TdThree}
TdFour={TdFour}
TdFive={TdFive}

TdSix={TdSix }
TdSeven={TdSeven}
TdEight={TdEight}
TdNine={TdNine}
TdTen={TdTen}
TdEleven={TdEleven}
TdTwelve={TdTwelve}
/>
    
    </>
  )
}