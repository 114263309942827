import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
import premium from "../../../assets/images/PbRound.jpg";
import economy from "../../../assets/images/PbSquare.jpg";
// import economy1 from "../../../assets/images/economy1.jpg";
import "../products.css";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";

function PileBreaker() {
  return (
    <div>
      <Topbanner title="Pile Breaker" Bg={paroBg} />

      <div className=" lb_product_wrap">
      <div className="row">
          <div className="col l6">
            <Heading styles="center" title="Pile Breaker (Round)" />
        </div>
        <div className="col l6">
            <img width={340} src={premium} alt="" />
        </div>
        
        </div>
        <div className="row">
          <div className="col l12">

            <PbRound/>

            <table className=" centered card z-depth-1">
              <thead className="rj_table_head">
                <tr>
                  <th>Model</th>
                  <th> Moduld number</th>
                  <th> Range Of Pile Breaker </th>
                  <th> Max Force for Single Rod</th>
                  <th> Max Stroke of Hydraulic Cylinder</th>
                  <th> Pressure of Hydraulic Cylinder</th>
                  <th> Max Flow of Single Cylinder</th>
                  <th> Max Height of each Crush</th>
                  <th> Suitable Carrier</th>
                  <th> Single Moulde Weight</th>
                  <th> Total Pile Breaker Weight</th>
                </tr>
              </thead>

              <tbody className="rj_table_body">
                <tr>
                  <td> Unit</td>
                  <td> Modules</td>
                  <td> mm</td>
                  <td> KN</td>
                  <td> mm</td>
                  <td> Mpa</td>
                  <td> L/Min</td>
                  <td> mm</td>
                  <td> T</td>
                  <td> KG</td>
                  <td> KG</td>
                </tr>
                <tr>
                  <td> BMPB800-12</td>
                  <td> 12</td>
                  <td> 1650-1800</td>
                  <td> 650</td>
                  <td> 215</td>
                  <td> 32-35</td>
                  <td> 25</td>
                  <td> &lt;=300</td>
                  <td> 35</td>
                  <td> 380</td>
                  <td> 4620</td>
                </tr>
                <tr>
                  <td> BMPB400-11</td>
                  <td> 11</td>
                  <td> 800-900</td>
                  <td> 350</td>
                  <td> 150</td>
                  <td> 32-35 </td>
                  <td> 25</td>
                  <td> &lt;=300</td>
                  <td> 21</td>
                  <td> 200</td>
                  <td> 1360</td>
                </tr>
                
              </tbody>
            </table>
            
          </div>
        </div>
      </div>

      <div className=" lb_product_wrap">
        
        <div className="row">
          <div className="col l6">
            <Heading styles="center" title="Pile Breaker (Square)" />
          </div>
          <div className="col l6">
            <img width={340} src={economy} alt="" />
          </div>
        
        </div>
        <div className="row">
          <div className="col l12">
            <PbSquare/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PileBreaker;



const  PbRound = ()=>{

  const TdTitle = ['Model',	'Unit',	'BMPB40-7',	'BMPB40-8',	'BMPB40-9',	'BMPB40-10',	'BMPB40-11',	'BMPB40-12',	'BMPB80-6',	'BMPB80-7',	'BMPB80-8',	'BMPB80-9',	'BMPB80-10',	'BMPB80-11',	'BMPB80-12',	'BMPB80-13']
  const TdOne = ['Moduld number',	'modules',	'7',	'8',	'9',	'10',	'11',	'12',	'6',	'7',	'8',	'9',	'10',	'11',	'12',	'13']
  const TdTwo = ['Range of pile breaker',	'mm',	'300-400',	'450-500',	'550-625',	'650-750',	'800-900',	'900-1050',	'500-600',	'650-800',	'850-1000',	'1050-1200',	'1250-1400',	'1450-1600',	'1650-1800',	'1850-2000']
  const TdThree = ['Suitable carrier',	'T',	'12',	'13',	'15',	'18',	'21',	'26',	'20',	'22',	'26',	'27',	'30',	'32',	'35',	'36']
  const TdFour  =['Total pile breaker weight',	'kg',	'920',	'1030',	'1140',	'1250',	'1360',	'1470',	'2335',	'2720',	'3100',	'3480',	'3860',	'4240',	'4620',	'5000']
  const TdFive  = ['Height of single crush pile',	'mm',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300',	'300' ]

    return(
   <>
   <ProductDetail 
  TdTitle={TdTitle}
  
  TdImg= {''}
  
  TdOne={TdOne} 
  TdTwo ={TdTwo }
  TdThree={TdThree}
  TdFour={TdFour}
  TdFive={TdFive}
  TdSix={'' }
  TdSeven={''}
  TdEight={''}
  TdNine={''}
  TdTen={''}
  TdEleven={''}
  TdTwelve={''}
  />
   
   </>
    )
  }


  const  PbSquare = ()=>{

    const TdTitle = ['Model',	'Unit',	'BMPB50']
    const TdOne = ['Moduld number',	'mouldes',	'4+4']
    const TdTwo = ['Range of pile breaker',	'mm',	'500-800']
    const TdThree = ['Max force for single rod',	'KN',	'350']
    const TdFour  =['Max stroke of hydraulic cylinder',	'mm',	'170']
    const TdFive  = ['Pressure of hydraulic cylinder',	'Mpa',	'32-35' ]
    const TdSix = ['Max flow for single cylinder',	'L/min',	'25']
    const TdSeven = ['Max height for each crush',	'mm',	'≤300']
    const TdEight = ['Suitable carrier',	'T',	'15-Oct']
    const TdNine = ['Single moulde weight',	'kg',	'250']
    const TdTen = ['Total pile breaker weight',	'kg',	'1300-1600']
  
      return(
     <>
     <ProductDetail 
    TdTitle={TdTitle}
    
    TdImg= {''}
    
    TdOne={TdOne} 
    TdTwo ={TdTwo }
    TdThree={TdThree}
    TdFour={TdFour}
    TdFive={TdFive}
    TdSix={TdSix}
    TdSeven={TdSeven}
    TdEight={TdEight}
    TdNine={TdNine}
    TdTen={TdTen}
    TdEleven={''}
    TdTwelve={''}
    />
     
     </>
      )
    }