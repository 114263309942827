import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
import premium from "../../../assets/images/Pile-Driver.jpg";
// import economy1 from "../../../assets/images/economy1.jpg";
import "../products.css";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";

function PileDriver() {
  return (
    <div>
      <Topbanner title="Pile Driver" Bg={paroBg} />

      <div className=" lb_product_wrap">
      <div className="row">
          <div className="col l8">
            <Heading styles="center" title="Pile Driver" />
        </div>
        <div className="col l4">
            <img width={300} src={premium} alt="" />
        </div>
        
        </div>
        <div className="row">
          <div className="col l12">
            <PdDetails/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PileDriver;



const  PdDetails = ()=>{


  const TdTitle = ['MODEL',  'UNIT', 'BMPD-08', 'BMPD-10', 'BMPD-14']
  const TdOne = ['Frequency', 'RPM', '2800', '2800', '3000']
  const TdTwo = ['Eccentric Torque', 'NM', '40', '50', '65']
  const TdThree = ['Vibration froce', 'TON', '38', '45', '60']
  const TdFour  =['Weight', 'KG', '2200', '2500', '2800']
  const TdFive  = ['Auxiliary Arm Weight', 'KG', '800', '800', '800' ]
  const TdSix  = ['Hydraulic System Operating Pressure', 'BAR', '280', '280', '300']
  const TdSeven = ['Hydraulic System Operating Flow', 'LPM', '160', '180', '210']
  const TdEight  = ['Max Pile Length', 'M', '9', '13', '16']
  const TdNine = ['Carrier', 'TON', '18-25', '26-35', '36-50']
    return(
   <>
   <ProductDetail 
  TdTitle={TdTitle}
  
  TdImg= {''}
  
  TdOne={TdOne} 
  TdTwo ={TdTwo }
  TdThree={TdThree}
  TdFour={TdFour}
  TdFive={TdFive}
  TdSix={TdSix }
  TdSeven={TdSeven}
  TdEight={TdEight}
  TdNine={TdNine}
  TdTen={''}
  TdEleven={''}
  TdTwelve={''}
  />
   
   </>
    )
  }