import React from "react";
import "./footer.css";

function Footer() {
  return (
    <div>
      <footer class="page-footer red accent-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col l3 s12">
              <h5 class="white-text">QUICK LINKS</h5>
              <ul>
                <li>
                  <a class="grey-text text-lighten-3" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/about">
                    About Us
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/gallary">
                    Gallery
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/network">
                    Our Network
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/contact">
                    Reach Out to Us
                  </a>
                </li>
              </ul>
            </div>
            <div class="col l3 s12">
              <h5 class="white-text">PRODUCTS</h5>

              <ul>
                <li>
                  <a class="grey-text text-lighten-3" href="/HydraulicBreaker">
                    Hydraulic Rock Breaker
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/VibratoryPlateCompactor">
                    Vibratory Plate Compactor
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/VibroRipper">
                    Vibro Ripper
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/HydraulicAttachment">
                    Rotary Hydraulic Crusher
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/HydraulicAttachment">
                    Rotatable Clamshell
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/HydraulicAttachment">
                    Rotatable Grapple
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/HydraulicAttachment">
                    Ripper
                  </a>
                </li>
              </ul>
            </div>
            <div class="col l3 s12">
              <h5 class="white-text">PRODUCTS</h5>

              <ul>
                <li>
                  <a class="grey-text text-lighten-3" href="/QuickCoupler">
                    Quick Coupler
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/DrumCutter">
                    Drum Cutter
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/EarthAuger">
                    Earth Auger
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/LongBoomAndArm">
                    Long Boom & Arm
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/PileBreaker">
                    Pile Breaker (Round & Square)
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/PileDriver">
                    Pile Driver
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="/ScrapMagnet">
                    Scrap Magnet
                  </a>
                </li>
              </ul>
            </div>
            <div class="col l3 s12">
              <h5 class="white-text">CONTACT US</h5>

              <ul>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Address: #103, B Block, Sumukha Paradise, Varadharajaswamy Layout,
                    Singapura, Bangalore-560097
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="tel:+916364433835">
                    +91-6364403835
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="mailto:ceo@bhasman.com">
                  info@bhasman.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <div class="container"> © 2021 Bhasman Machineries Pvt Ltd | All Right Reserved
             <div class=" right">Designed By <a class="white-text"  href="https://rcsinfo.in/" target="_blank" rel="noreferrer"><u>RCS Technology</u></a></div> 
            
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
