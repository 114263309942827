import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
import "../products.css";
import GearBox from "../../../components/IndustrialGearBox/GearBox";

function QuickCoupler() {
  return (
    <div>
      <Topbanner title="Industrial Gear Box" Bg={paroBg} />

      <div className=" lb_product_wrap">
        <Heading styles="center" title="Industrial Gear Box" />
        <div className="row">
          <div className="col l12">
                <ul>
                    <li class="raj">Worm Gear Boxex (1.625" to 10" CD Horizontal / Vertical Type)</li>
                    <li class="raj">Hollow Shaft Worm Gear Boxes (0.25 HP to 5 HP) </li>
                    <li class="raj">Geared Motors (0.25 HP to 60 HP Foot / Flange Type) </li>
                    <li class="raj">Helical / Bevel Reduction Gear Boxes</li>
                    <li class="raj">Extrauder Gear Boxes </li>
                    <li class="raj">Areator Gear Boxes (For Aqua Culture)</li>
                    <li class="raj">Shaft Mounted Gear Boxes</li>
                    <li class="raj">Non Standard / Custom Built Gear Boxes</li>
                    <li class="raj">Electric Motor : Single Phase / Three Phase / Foot & Flange / Brake Motor</li>
                    <li class="raj">Loose Gears : Spur / Helical / Bevel / Worm & Wheel Pairs</li>
                    <li class="raj">Spare For Other Make Gear Boxes</li>
                    <li class="raj">Chain / Sprockets / Gear Couplings / Pin Bush Coupling / Tyre & Flange Couplings</li>
                </ul> <br/>

              <GearBox />

          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickCoupler;
