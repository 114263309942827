import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
//import premium from "../../../assets/images/drumcutter.jpg";
//import economy from "../../../assets/images/drumcutter.jpg";
// import economy1 from "../../../assets/images/economy1.jpg";
import "../products.css";
import ProductDetail from "../../../components/ProductDetail/ProductDetail";

function BoomAndArm() {
  return (
    <div>
      <Topbanner title="Long Boom and Arm" Bg={paroBg} />

      <div className=" lb_product_wrap">
        <Heading styles="center" title="Long Boom and Arm" />
        <div className="row">
          <div className="col l12">
              
              <BoomArm/>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BoomAndArm;

const  BoomArm = ()=>{


  const TdTitle = ['Models -->' ,'Unit ' ,'12T', '20T', '22T', '30T', '35T', '40T', '45']
  const TdOne = ['Length of the boom arm', 'mm', '13000',	'15380',	'15380',	'18000',	'20000',	'22000',	'24000']
  const TdTwo = ['Working weight', 'kg',	'3000',	'4000',	'4200',	'5200',	'6000',	'6500',	'7000']
  const TdThree = ['Bucket capacity', 'm3',	'0.3',	'0.4',	'0.4',	'0.4',	'0.5',	'0.6',	'0.6']
  const TdFour  =['Max digging height', 'mm',	'11300',	'12510',	'12610',	'13720',	'14920',	'16100',	'17300']
  const TdFive  = ['Max reaching depth', 'mm',	'12500',	'15100',	'15100',	'17600',	'19600',	'21600',	'23600']
  const TdSix  = ['Max digging depth', 'mm',	'9000',	'11340',	'11370',	'13000',	'15000',	'16500',	'18000']
  const TdSeven = ['Total height', 'mm',	'2900',	'2980',	'3040',	'3210',	'3210',	'3400',	'3400']
  const TdEight  = ['Counter weight', 'kg',	'1500',	'1800',	'2000',	'2500',	'2000',	'2500',	'3000']
    return(
   <>
   <ProductDetail 
  TdTitle={TdTitle}
  
  TdImg= {''}
  
  TdOne={TdOne} 
  TdTwo ={TdTwo }
  TdThree={TdThree}
  TdFour={TdFour}
  TdFive={TdFive}
  TdSix={TdSix }
  TdSeven={TdSeven}
  TdEight={TdEight}
  TdNine={''}
  TdTen={''}
  TdEleven={''}
  TdTwelve={''}
  />
   
   </>
    )
  }
  