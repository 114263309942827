import React from 'react';


const ProductDetail = ({TdTitle,TdImg ,TdOne ,TdTwo ,TdThree, TdFour, TdFive ,TdSix ,TdSeven ,TdEight,TdNine,TdTen,TdEleven,TdTwelve }) => {

    return (
        <table className=" centered card z-depth-1">
    <thead className="rj_table_head">

        <TableHead TdTitle={TdTitle}/>
     
    </thead>

    <tbody className="rj_table_body">
        <TableImg  TdImg={TdImg} />
       <TableDataOne  TdOne={TdOne} />
       <TableDataTwo TdTwo={TdTwo } />
       <TableDataThree TdThree={TdThree } />
       <TableDataFour TdFour={TdFour } />
       <TableDataFive TdFive={TdFive } />
       <TableDataSix TdSix={TdSix } />
       <TableDataSeven TdSeven={TdSeven } />
       <TableDataEight TdEight={TdEight } />
       <TableDataNine TdNine={TdNine } />
       <TableDataTen TdTen={TdTen } />
       <TableDataEleven TdEleven={TdEleven } />
       <TableDataTwelve TdTwelve={TdTwelve } />
    </tbody>
  </table>
    )
}

export default ProductDetail;



//tdtitler

const TableHead = ({ TdTitle}) =>{
   
   if(TdTitle.length === 0){
       return ''
   }else{
   return(
       <>
        <tr>
       {TdTitle.map((item)=>{
            return(
               <th className='center'>{item} </th>
            )
       })}
       </tr>
              </>
   )      
   }}


   //td img
   const TableImg  = ({ TdImg }) =>{
    
   if(TdImg.length === 0){
       return ''
   }else{
   return(
       <>
       
       
        <tr>
        <td></td>
       {TdImg.map((item)=>{
            return(
                <td>
                <img key={item.size} width={item.size} src={item.premium} alt="" />
              </td>
            )
       })}
       </tr>
              </>
   )
      
   }}

////teable data


//t1
const TableDataOne = ({term , TdOne}) =>{
  
   if(TdOne.length === 0){
       return ''
   }else{
   return(
       <>
        <tr>
       {TdOne.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}

//    //t2
   const TableDataTwo = ({term , TdTwo }) =>{
   
   if(TdTwo.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdTwo.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}
   
//    //t3

const TableDataThree = ({term , TdThree }) =>{
   
   if(TdThree.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdThree.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}


  //t4

const TableDataFour = ({term , TdFour }) =>{
    
   if(TdFour.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdFour.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}


//    ///t5

const TableDataFive = ({term , TdFive }) =>{
    
   if(TdFive.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdFive.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}
   

////t6

const TableDataSix = ({term , TdSix }) =>{
   
   if(TdSix.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdSix.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}


   //t7
   const TableDataSeven= ({term , TdSeven}) =>{
   
   if(TdSeven.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdSeven.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}


   //t8

   const TableDataEight = ({term , TdEight }) =>{
   
   if(TdEight.length === 0){
       return ''
   }else{
   return(
       <>
       <tr>
       {TdEight.map((item)=>{
            return(
               <td>{item} </td>
            )
       })}
       </tr>
              </>
   )
      
   }}


   //t9

   const TableDataNine = ({term , TdNine }) =>{
   
    if(TdNine.length === 0){
        return ''
    }else{
    return(
        <>
        <tr>
        {TdNine.map((item)=>{
             return(
                <td>{item} </td>
             )
        })}
        </tr>
               </>
    )
       
    }}

    //t10

   const TableDataTen = ({term , TdTen }) =>{
   
    if(TdTen.length === 0){
        return ''
    }else{
    return(
        <>
        <tr>
        {TdTen.map((item)=>{
             return(
                <td>{item} </td>
             )
        })}
        </tr>
               </>
    )
       
    }}

    //t11

   const TableDataEleven = ({term , TdEleven }) =>{
   
    if(TdEleven.length === 0){
        return ''
    }else{
    return(
        <>
        <tr>
        {TdEleven.map((item)=>{
             return(
                <td>{item} </td>
             )
        })}
        </tr>
               </>
    )
       
    }}

    //t12

   const TableDataTwelve = ({term , TdTwelve }) =>{
   
    if(TdTwelve.length === 0){
        return ''
    }else{
    return(
        <>
        <tr>
        {TdTwelve.map((item)=>{
             return(
                <td>{item} </td>
             )
        })}
        </tr>
               </>
    )
       
    }}





