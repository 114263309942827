import React from "react";
import Topbanner from "../../../components/Topbaner/Topbanner";
import paroBg from "../../../assets/images/topbanners/product.jpg";
import Heading from "../../../helpers/Headings/Heading";
import premium from "../../../assets/images/Scrap-Magnet.jpg";
import economy from "../../../assets/images/Scrap-Magnet1.jpg";
// import economy1 from "../../../assets/images/economy1.jpg";
import "../products.css";

function drumcutter() {
  return (
    <div>
      <Topbanner title="Scrap Magnet" Bg={paroBg} />

      <div className=" lb_product_wrap">
        <div className="row">
            <div className="col l4">
              <Heading styles="center" title="Scrap Magnet" />
          </div>
          <div className="col l8">
              <img width={340} src={premium} alt="" />
              <img width={340} src={economy} alt="" />
          </div>
          
        </div>
        <div className="row">
          <div className="col l12">
            <table className=" centered card z-depth-1">
              <thead className="rj_table_head">
                <tr>
                  <th>Model</th>
                  <th> Current</th>
                  <th> Cold Power </th>
                  <th colSpan={3}> Lifting capacity (Cold/Hot)(KG)</th>
                  <th> Weight</th>
                  <th> Excavator</th>
                </tr>
              </thead>

              <tbody className="rj_table_body">
                <tr className="rj_table_head2">
                  <td></td>
                  <td>(A)</td>
                  <td>(KW) </td>
                  <td>Steel Ball</td>
                  <td>Steel Block</td>
                  <td>Iron Filings</td>
                  <td>(KG) </td>
                  <td>(T) </td>
                </tr> 
                <tr>
                  <td>BMEM-60L</td>
                  <td>11</td>
                  <td>2.3</td>
                  <td>2000</td>
                  <td>280/170 </td>
                  <td>280/100</td>
                  <td>400</td>
                  <td>2-4</td>
                </tr> 
                <tr>
                  <td>BMEM-70L</td>
                  <td>15</td>
                  <td>3.3</td>
                  <td>2500</td>
                  <td>380/200 </td>
                  <td> 380/200 </td>
                  <td>500</td>
                  <td>3-5</td>
                </tr> 
                <tr>
                  <td>BMEM-80L</td>
                  <td>18</td>
                  <td>3.96</td>
                  <td>3000</td>
                  <td>480/250</td>
                  <td>480/250</td>
                  <td>600</td>
                  <td>5-8</td>
                </tr> 
                <tr>
                  <td>BMEM-90L</td>
                  <td>26.6</td>
                  <td>5.9</td>
                  <td>4500</td>
                  <td> 650/400 </td>
                  <td> 650/400</td>
                  <td>760</td>
                  <td>10-12</td>
                </tr> 
                <tr>
                  <td>BMEM-100L</td>
                  <td>30</td>
                  <td>7</td>
                  <td>6500</td>
                  <td>1000/800 </td>
                  <td> 1000/800</td>
                  <td>900</td>
                  <td>12-15</td>
                </tr> 
                <tr>
                  <td>BMEM-110L</td>
                  <td>35</td>
                  <td>6.1</td>
                  <td>6500</td>
                  <td>1050/800</td>
                  <td> 1050/800 </td>
                  <td>1200</td>
                  <td>15-18</td>
                </tr> 
                <tr>
                  <td>BMEM-120L</td>
                  <td>45.5</td>
                  <td>7.4</td>
                  <td>7500</td>
                  <td> 1300/100</td>
                  <td>1300/100</td>
                  <td>1500</td>
                  <td>18-20</td>
                </tr> 
                <tr>
                  <td>BMEM-130L</td>
                  <td>54</td>
                  <td>9</td>
                  <td>8500</td>
                  <td> 1450/1100</td>
                  <td> 1450/1100</td>
                  <td>1700</td>
                  <td>20-25</td>
                </tr> 
                <tr>
                  <td>BMEM-150L</td>
                  <td>71.2</td>
                  <td>11.3</td>
                  <td>11000</td>
                  <td> 1980/1500</td>
                  <td> 1980/1500 </td>
                  <td>2400</td>
                  <td>25-35</td>
                </tr> 
                <tr>
                  <td>BMEM-165L</td>
                  <td>75</td>
                  <td>12.2</td>
                  <td>12500</td>
                  <td> 2300/1800</td>
                  <td>2300/1800 </td>
                  <td>2700</td>
                  <td>30-35</td>
                </tr> 
                <tr>
                  <td>BMEM-180L</td>
                  <td>102</td>
                  <td>16.3</td>
                  <td>14500</td>
                  <td> 2850/2200</td>
                  <td>2850/2200</td>
                  <td>3500</td>
                  <td>35-45</td>
                </tr> 
                <tr>
                  <td>BMEM-210L</td>
                  <td>129</td>
                  <td>21.7</td>
                  <td>21000</td>
                  <td> 2300/1900</td>
                  <td> 2300/1900</td>
                  <td>4800</td>
                  <td>45-70</td>
                </tr> 
                <tr>
                  <td>BMEM-240L</td>
                  <td>154</td>
                  <td>25.9</td>
                  <td>26000</td>
                  <td> 4850/3900</td>
                  <td> 4850/3900</td>
                  <td>9000</td>
                  <td>70-100</td>
                </tr> 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default drumcutter;
