import Gears1 from "../images/gearbox/gb1.jpg";
import Gears2 from "../images/gearbox/gb2.jpg";
import Gears3 from "../images/gearbox/gb3.jpg";
import Gears4 from "../images/gearbox/gb4.jpg";
import Gears5 from "../images/gearbox/gb5.jpg";
import Gears6 from "../images/gearbox/gb6.jpg";
import Gears7 from "../images/gearbox/gb7.jpg";
import Gears8 from "../images/gearbox/gb8.jpg";
import Gears9 from "../images/gearbox/gb9.jpg"; 
import Gears10 from "../images/gearbox/gb10.jpg";
import Gears11 from "../images/gearbox/gb11.jpg";
import Gears12 from "../images/gearbox/gb12.jpg";
import Gears13 from "../images/gearbox/gb13.jpg";
import Gears14 from "../images/gearbox/gb14.jpg";
import Gears15 from "../images/gearbox/gb15.jpg";
// import Gears16 from "../images/gearbox/16.jpg";
// import Gears17 from "../images/gearbox/17.jpg";
// import Gears18 from "../images/gearbox/18.jpg";
// import Gears19 from "../images/gearbox/19.jpg";


const ToolCategory = [
  {
    id: 1,
    Url:"/GearBox",
    imgUrl: Gears1,
    text: "Shaft Mounted Gear Box",
  },
  {
    id: 2,
    Url:"/GearBox",
    imgUrl: Gears2,
    text: "Horizontal Type Gear Box",
  },
  {
    id: 3,
    Url:"/GearBox",
    imgUrl: Gears3,
    text: "Hollow Input Gear Box",
  },
  {
    id: 4,
    Url:"/GearBox",
    imgUrl: Gears4,
    text: "Bevel Helical Gear Box",
  },
  {
    id: 5,
    Url:"/GearBox",
    imgUrl: Gears5,
    text: "Double Reduction Gear Box",
  },
  {
    id: 6,
    Url:"/GearBox",
    imgUrl: Gears6,
    text: "Vertical Type Gear Box",
  },
  {
    id: 7,
    Url:"/GearBox",
    imgUrl: Gears7,
    text: "Horizontal Type Gear Box",
  },
  {
    id: 8,
    Url:"/GearBox",
    imgUrl: Gears8,
    text: "Extruder Gear Box",
  },
  {
    id: 9,
    Url:"/GearBox",
    imgUrl: Gears9,
    text: "Vertical Type Gear Box",
  },
  {
    id: 10,
    Url:"/GearBox",
    imgUrl: Gears10,
    text: "Planetary Gear Box",
  },
  {
    id: 11,
    Url:"/GearBox",
    imgUrl: Gears11,
    text: "Foot Mounted Geared Motor",
  },
  {
    id: 12,
    Url:"/GearBox",
    imgUrl: Gears12,
    text: "Flange Mounted Geared Motor",
  },
  {
    id: 13,
    Url:"/GearBox",
    imgUrl: Gears13,
    text: "Worm Reduction Gear Box",
  },
  {
    id: 14,
    Url:"/GearBox",
    imgUrl: Gears14,
    text: "Double Worm Reduction Gear Box",
  },
  {
    id: 15,
    Url:"/GearBox",
    imgUrl: Gears15,
    text: "Solid Input/ Hollow Output Gear box",
  },
];

export default ToolCategory;
